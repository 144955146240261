import React, { useContext } from "react";
import { useState, useEffect } from "react";
// import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordChecklist from "react-password-checklist";
import { Select2Data, formatDate, getPaginationMessage } from "../../../../utils/common";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { Container, } from "react-bootstrap";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, } = useContext(Context);
  
  const id = props.show;
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const [iconCurrent, setIconCurrent] = useState(faEyeSlash);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  console.log(showModal,"showModal showModal");
  
  const GetEditData = async () => {
    const response = await getData(`/masters/employee/${id}`);
    reset(response?.data);
  };

  const GetAllCountries = async () => {
    const response = await getData("/without-login/masters/all-country");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getData(`/without-login/masters/all-states/${id}`);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await getData(`/without-login/masters/all-city/${id}`);

    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };
  const GetAllPincode = async () => {
    const response = await getData(`/without-login/masters/all-occupation`);

    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  useEffect(() => {
    GetAllPincode();
    GetEditData();
    GetAllCountries();
    GetAllStates();
    GetAllCities();
  }, []);
  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    } else if (field === "current_password") {
      setShowPasswordCurrent(!showPasswordCurrent);
      setIconCurrent(showPasswordCurrent ? faEyeSlash : faEye);
    }
  };
  const [password, setPassword] = useState("")
  const [passwordCurrent, setPasswordCurrent] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    
   
    try {
      const finalData = new FormData();

      finalData.append("country_id", data.country_id?.value);
      finalData.append("state_id", data.state_id?.value);
      finalData.append("city_id", data.city_id?.value);
      finalData.append("occupation_id", data.occupation_id?.value);
      finalData.append("f_name", data.f_name);
      finalData.append("contact_no", data.contact_no);
      finalData.append("email", data.email);
      finalData.append("l_name", data.l_name);
      finalData.append("age", data.age);
      finalData.append("gender", data.gender);
      // finalData.append("password", data.re_password);
      const response = await postData(
        `/masters/employee/${id}`,
        finalData
      );
      console.log(response);
      if (response.success) {
        console.log("kkkkkkkkkkkk");
        
        setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 3000);
      } else {
        setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
    // if (data.re_password === data.c_password) {
    
    // } else {
    //   setError("password", {
    //     message: "Passwords must match",
    //   });
    // }
  };

  console.log(getValues(),"ggggggggggggggggggggg");
  
  return (
    <>
   <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add FAQ</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Edit Employee</Card.Title>
              <hr />
              <Container>
              <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="">
                      <Form.Label>First Name</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="f_name"
                        placeholder="First Name"
                        className={classNames("", {
                          "is-invalid": errors?.f_name,
                        })}
                        {...register("f_name", {
                          required: "Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.f_name && (
                      <span className="text-danger">
                        {errors.f_name.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="">
                      <Form.Label>Last Name</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="l_name"
                        placeholder="Last Name"
                        className={classNames("", {
                          "is-invalid": errors?.l_name,
                        })}
                        {...register("l_name", {
                          required: "Last Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.l_name && (
                      <span className="text-danger">
                        {errors.l_name.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="">
                      <Form.Label>Contact</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="contact_no"
                        placeholder="Brand"
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                        })}
                        {...register("contact_no", {
                          required: "contact_no is required",
                          minLength: {
                            value: 10,
                            message:
                              "Number should be at least 10 characters",
                          },
                        })}
                        onKeyDown={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    </InputGroup>
                    {errors.contact_no && (
                      <span className="text-danger">
                        {errors.contact_no.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="">
                      <Form.Label>Email</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="email"
                        className={classNames("", {
                          "is-invalid": errors?.email,
                        })}
                        {...register("email", {
                          required: "email is required",
                          pattern: {
                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                            message: "Email Id Invalid",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
             
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="">
                      <Form.Label>Gender</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="gender"
                        placeholder="Gender"
                        className={classNames("", {
                          "is-invalid": errors?.gender,
                        })}
                        {...register("gender", {
                          required: "gender is required",
                        })}
                      />
                    </InputGroup>
                    {errors.gender && (
                      <span className="text-danger">
                        {errors.gender.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Form.Group>
                    <div className="">
                      <Form.Label>Age</Form.Label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="age"
                        placeholder="age"
                        className={classNames("", {
                          "is-invalid": errors?.age,
                        })}
                        {...register("age", {
                          required: "age is required",
                        })}
                      />
                    </InputGroup>
                    {errors.age && (
                      <span className="text-danger">
                        {errors.age.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Occupation</Form.Label>

                    <Controller
                      name="occupation_id" // name of the field
                      {...register("occupation_id", {
                        required: "Select occupation",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={pincode}
                        />
                      )}
                    />

                    {errors.occupation && (
                      <span className="text-danger">
                        {errors.occupation.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Country</Form.Label>

                    <Controller
                      name="country_id" // name of the field
                      {...register("country_id", {
                        required: "Select Country",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={country}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllStates(selectedOption.value);
                            setValue("country_id", selectedOption);
                            setValue("state_id", null);
                            setValue("city_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.country_id && (
                      <span className="text-danger">
                        {errors.country_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>State</Form.Label>

                    <Controller
                      name="state_id" // name of the field
                      {...register("state_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={states}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllCities(selectedOption.value);
                            setValue("state_id", selectedOption);
                            setValue("city_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section my-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>City</Form.Label>

                    <Controller
                      name="city_id" // name of the field
                      {...register("city_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={cities}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllPincode(selectedOption.value);
                            setValue("city_id", selectedOption);
                            setValue("pincode_id", null);
                          }}
                        />
                      )}
                    />

                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

            

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />


    </>
  );
};

export default EditOffCanvance;
