import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

import "./App.css";

import Login from "./Components/Admin/Login/Login";
import Sidebar from "./Components/Admin/Sidebar/Sidebar";
import Header from "./Components/Admin/Header/Header";
import Order from "./Components/Admin/DashBoard/Order/Order";
import Country from "./Components/Admin/Masters/Country/Tables";
import State from "./Components/Admin/Masters/State/Tables";
import City from "./Components/Admin/Masters/City/Tables";
import Pincode from "./Components/Admin/Masters/Pincode/Tables";
import Occupation from "./Components/Admin/Masters/Occupation/Tables";
import FAQ from "./Components/Admin/Masters/Faq/Tables";
import AppSetup from "./Components/Admin/Masters/App_setup/Tables";
import Calendars from "./Components/Admin/Masters/Calendar/Tables";
import DashboardMain from "./Components/Admin/DashboardMain/DashboardMain/Tables";
import Investor from "./Components/Admin/Investor/Investor/Tables";
import Investorledger from "./Components/Admin/Investorledger/Investorledger/Tables";
import Inward from "./Components/Admin/Inward/Inward/Tables";
import OutWard from "./Components/Admin/Outward/Outward/Tables";
import Transfer from "./Components/Admin/Transfer/transfer/Tables";
import Notification from "./Components/Admin/Notification/Notification/Tables";
import Operation from "./Components/Admin/Operations/Operations/Tables";
import RateUs from "./Components/Admin/RateUs/RateUs/Tables";
import Pnl from "./Components/Admin/PnL/Pnl/Table";
import Pnlclient from "./Components/Admin/PnL/Pnl_client/Tables";
import PnlTransaction from "./Components/Admin/PnL/PnlTransaction/Tables";
import { Context } from "./utils/context";
import { Calendar } from "antd";
import Employee from "./Components/Admin/Masters/Epmloyee/Tables";

import Role from "./Components/Admin/Masters/Role/Table";

import PageNotFound from "../src/Components/Admin/PageNotFound/PageNotFound";
import { IDS } from "./utils/common";
library.add(fas);

const App = () => {
  const { signin ,isAllow,isSidebarOpen} = useContext(Context);
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const isLoginPage = location.pathname === "/";
  const toggleSidebar = () => {
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "/",
        element: <Login />,
        status: true,
      },
      {
        path: "/Header",
        element: <Header />,
        status: true,
      },
      {
        path: "/dashboard",
        element: <DashboardMain />,
        status: isAllow?.includes(IDS.Dashboard.TotalRegistration),
      },
      {
        path: "/orders",
        element: <Order />,
        status: true,
      },
      {
        path: "/investor",
        element: <Investor />,
        status: isAllow?.includes(IDS.Investor.List),
      },
      {
        path: "/investor-ledger",
        element: <Investorledger />,
        status: isAllow?.includes(IDS.InvestorLedger.List),
      },
      {
        path: "/inward",
        element: <Inward />,
        status: isAllow?.includes(IDS.InwardPayment.InvestorList),
      },
      {
        path: "/outward",
        element: <OutWard />,
        status: isAllow?.includes(IDS.OutwardPayment.UsersList),
      },
      {
        path: "/transfer",
        element: <Transfer />,
        status: isAllow?.includes(IDS.TransferPayment.List),
      },
      {
        path: "/notification",
        element: <Notification />,
        status: isAllow?.includes(IDS.Notification.List),
      },
      {
        path: "/rate-us",
        element: <RateUs />,
        status: isAllow?.includes(IDS.RateUs.List),
      },
      {
        path: "/pnl",
        element: <Pnl />,
        status: isAllow?.includes(IDS.PNLClientTransaction.List),
      },
      {
        path: "/pnl-client",
        element: <Pnlclient />,
        status: isAllow?.includes(IDS.PnlClient.List),
      },
      {
        path: "/pnl-transaction",
        element: <PnlTransaction />,
        status: isAllow?.includes(IDS.PnlTransaction.List),
      },
      {
        path: "/operations-main",
        element: <Operation />,
        status: isAllow?.includes(IDS.AppOperations.List),
      },
    
      {
        path: "/masters/country",
        element: <Country />,
        status: true,
      },
      {
        path: "/masters/state",
        element: <State />,
        status: true,
      },
      {
        path: "/masters/city",
        element: <City />,
        status: true,
      },
      {
        path: "/masters/pincode",
        element: <Pincode />,
        status: true,
      },
      {
        path: "/masters/occupation",
        element: <Occupation />,
        status: true,
      },
      {
        path: "/masters/faq",
        element: <FAQ />,
        status: isAllow?.includes(IDS.FAQ.List),
      },
      {
        path: "/masters/app-setup",
        element: <AppSetup />,
        status: isAllow?.includes(IDS.AppSetup.List),
      },
      {
        path: "/masters/calendar",
        element: <Calendars />,
        status: isAllow?.includes(IDS.Calendar.List),
      },
      {
        path: "/masters/employee",
        element: <Employee />,
        status: isAllow?.includes(IDS.Employee.List),
      },
      {
        path: "/masters/role",
        element: <Role />,
        status: isAllow?.includes(IDS.Role.List),
      },
      
    ]);
  }, [isAllow]);

  return (
    // <div className="top_section">
    //    {isLoginPage ? (
    //     <Login />
    //   ) : (
    //     <div className=" d-flex sidebar-x-scroll">
    //       <div
    //         className={`${
    //           isSidebarOpen
    //             ? "sidebarHeadopen "
    //             : "scale-in-hor-left sidebarHeadClose"
    //         }  pe-0`}
    //       >
    //         <Sidebar />
    //       </div>
    //       <div
    //         className={`${
    //           isSidebarOpen
    //             ? "dashboradopen"
    //             : "scale-in-hor-right dashboradopen"
    //         }  ps-0`}
    //       >
    //         <div className="allRoutesMain">
    //           <Routes>
    //             {urls?.map(
    //               (url) =>
    //                 url.status && (
    //                   <Route path={url.path} element={url.element} />
    //                 )
    //             )}
    //             <Route path="*" element={<PageNotFound />} />
    //           </Routes>
    //         </div>
    //         {/* <Home /> */}
    //       </div>
    //     </div>
    //   )}
    // </div>
    <div className="top_section">
    {signin && (
      <>
        <div className="sub-header">
          <Row>
            <Col xxl={6} xl={6} lg={6}>
              <div className="main-sub-header">
                <h3 className="Itlogtitle">ProfitKing ADMIN</h3>
                <div className="icon">
                  <FaBars onClick={toggleSidebar} />
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6}>
              <div className="admin-header">
                <div className="user">
                  <FontAwesomeIcon
                    icon={["fas", "user"]}
                    className="user-icon"
                  />
                </div>
                <div className="text">
                  <p>Profitking/Admin</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div
          className={`${sidebarOpen ? "row ms-0 me-0" : " d-flex "
            } main-content`}
        >
          <div
            className={`ps-0 ${sidebarOpen
                ? "col-xxl-2 col-xl-2 col-lg-3 col-md-2 p-0"
                : "sidebar-column"
              } `}
          >
            <Sidebar isOpen={sidebarOpen} />
          </div>
          <div
            className={`${sidebarOpen
                ? "col-xxl-10 col-xl-10 col-lg-9 p-0"
                : " main-content"
              } `}
          >
            <Routes>
                {urls?.map(
                  (url) =>
                    url.status && (
                      <Route path={url.path} element={url.element} />
                    )
                )}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
          </div>
        </div>
      </>
    )}

    {!signin && <Login />}
  </div>
  );
};

export default App;





// import React, { useContext, useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useLocation,
//   Navigate,
// } from "react-router-dom";
// import { FaBars } from "react-icons/fa";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Col, Row } from "react-bootstrap";

// import "./App.css";

// import Login from "./Components/Admin/Login/Login";
// import Sidebar from "./Components/Admin/Sidebar/Sidebar";
// import Header from "./Components/Admin/Header/Header";
// import Order from "./Components/Admin/DashBoard/Order/Order";
// import Country from "./Components/Admin/Masters/Country/Tables";
// import State from "./Components/Admin/Masters/State/Tables";
// import City from "./Components/Admin/Masters/City/Tables";
// import Pincode from "./Components/Admin/Masters/Pincode/Tables";
// import Occupation from "./Components/Admin/Masters/Occupation/Tables";
// import FAQ from "./Components/Admin/Masters/Faq/Tables";
// import AppSetup from "./Components/Admin/Masters/App_setup/Tables";
// import Calendars from "./Components/Admin/Masters/Calendar/Tables";
// import DashboardMain from "./Components/Admin/DashboardMain/DashboardMain/Tables";
// import Investor from "./Components/Admin/Investor/Investor/Tables";
// import Investorledger from "./Components/Admin/Investorledger/Investorledger/Tables";
// import Inward from "./Components/Admin/Inward/Inward/Tables";
// import OutWard from "./Components/Admin/Outward/Outward/Tables";
// import Transfer from "./Components/Admin/Transfer/transfer/Tables";
// import Notification from "./Components/Admin/Notification/Notification/Tables";
// import Operation from "./Components/Admin/Operations/Operations/Tables";
// import RateUs from "./Components/Admin/RateUs/RateUs/Tables";
// import Pnl from "./Components/Admin/PnL/Pnl/Table";
// import Pnlclient from "./Components/Admin/PnL/Pnl_client/Tables";
// import PnlTransaction from "./Components/Admin/PnL/PnlTransaction/Tables";
// import { Context } from "./utils/context";
// import { Calendar } from "antd";
// import Employee from "./Components/Admin/Masters/Epmloyee/Tables";

// import Role from "./Components/Admin/Masters/Role/Table";
// library.add(fas);

// const App = () => {
//   const { signin } = useContext(Context);
//   const location = useLocation();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [sidebarOpen, setSidebarOpen] = useState(true);

//   const toggleSidebar = () => {
//     setSidebarOpen((prevOpen) => !prevOpen);
//   };

//   // const handleLogin = () => {
//   //   setIsLoggedIn(true);
//   // };

//   // const handleLogout = () => {
//   //   setIsLoggedIn(false);
//   // };

//   // useEffect(() => {
//   //   setIsLoggedIn(location.pathname !== "/login");
//   // }, [location.pathname]);

//   return (
//     <div className="top_section">
//       {signin && (
//         <>
//           <div className="sub-header">
//             <Row>
//               <Col xxl={6} xl={6} lg={6}>
//                 <div className="main-sub-header">
//                   <h3 className="Itlogtitle">ProfitKing ADMIN</h3>
//                   <div className="icon">
//                     <FaBars onClick={toggleSidebar} />
//                   </div>
//                 </div>
//               </Col>
//               <Col xxl={6} xl={6} lg={6}>
//                 <div className="admin-header">
//                   <div className="user">
//                     <FontAwesomeIcon
//                       icon={["fas", "user"]}
//                       className="user-icon"
//                     />
//                   </div>
//                   <div className="text">
//                     <p>Profitking/Admin</p>
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//           </div>

//           <div
//             className={`${sidebarOpen ? "row ms-0 me-0" : " d-flex "
//               } main-content`}
//           >
//             <div
//               className={`ps-0 ${sidebarOpen
//                   ? "col-xxl-2 col-xl-2 col-lg-3 col-md-2 p-0"
//                   : "sidebar-column"
//                 } `}
//             >
//               <Sidebar isOpen={sidebarOpen} />
//             </div>
//             <div
//               className={`${sidebarOpen
//                   ? "col-xxl-10 col-xl-10 col-lg-9 p-0"
//                   : " main-content"
//                 } `}
//             >
//               <Routes>
//                 {/* <Route path="/" element={<Navigate to="/" />} /> */}
//                 <Route path="/" element={<Login />} />
//                 <Route path="/header" element={<Header />} />
//                 <Route path="/dashboard" element={<DashboardMain />} />
//                 <Route path="/orders" element={<Order />} />
//                 <Route path="/investor" element={<Investor />} />
//                 <Route path="/investor-ledger" element={<Investorledger />} />
//                 <Route path="/inward" element={<Inward />} />
//                 <Route path="/outward" element={<OutWard />} />
//                 <Route path="/transfer" element={<Transfer />} />
//                 <Route path="/notification" element={<Notification />} />
//                 <Route path="/rate-us" element={<RateUs />} />{" "}
//                 <Route path="/pnl" element={<Pnl />} />
//                 <Route path="/pnl-client" element={<Pnlclient />} />
//                 <Route path="/pnl-transaction" element={<PnlTransaction />} />
//                 <Route path="/operations-main" element={<Operation />} />
//                 {/* Masters Routes */}
//                 <Route path="/masters/country" element={<Country />} />
//                 <Route path="/masters/state" element={<State />} />
//                 <Route path="/masters/city" element={<City />} />
//                 <Route path="/masters/pincode" element={<Pincode />} />
//                 <Route path="/masters/occupation" element={<Occupation />} />
//                 <Route path="/masters/faq" element={<FAQ />} />
//                 <Route path="/masters/app-setup" element={<AppSetup />} />
//                 <Route path="/masters/calendar" element={<Calendars />} />
//                 {/* Payment Routes */}
//                 <Route
//                   path="/payment/inward-payment"
//                   element={<DashboardMain />}
//                 />

//                 <Route
//                   path="/masters/employee"
//                   element={<Employee />}
//                 />
//                  <Route
//                   path="/masters/role"
//                   element={<Role />}
//                 />
//               </Routes>
//             </div>
//           </div>
//         </>
//       )}

//       {!signin && <Login />}
//     </div>
//   );
// };

// export default App;
