import React, { useEffect, useState,useContext } from "react";

import "react-datepicker/dist/react-datepicker.css";
// import "./AdvanceDashboard.css";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faGauge, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactApexChart from "react-apexcharts";
import { getAllData } from "../../../../utils/apis/Dashborad/Dashborad";
import { Context } from "../../../../utils/context";
library.add(fas);
function Table() {
  const [startDate, setStartDate] = useState(new Date());

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const { IMG_URL, isAllow } = useContext(Context);
  const toggleDatePicker = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };

  // Graph related
  const dotas = [
    23, 20, 20, 27, 10, 22, 11, 14, 22, 22, 12, 16, 23, 42, 35, 27, 43, 22, 17,
  ];

  // Update chart data when dotas changes
  // useEffect(() => {
  // setChartOptions((prevOptions) => ({
  //   ...prevOptions,
  //   series: prevOptions.series.map((series) =>
  //     series.name === "Total Withdrawal" ? { ...series, data: dotas } : series
  //   ),
  // }));
  // }, [dotas]);

  const [data, setData] = useState({});

  const getData = async () => {
    const res = await getAllData();
    await setData(res?.data);
    await setChartOptions({
      series: [
        
        {
          name: "Total Registered",
          type: "column",
          data: res?.data?.chart?.Count,
          color: "#008FFB",
        },
        {
          name: "Total Fund Added Users",
          type: "line",
          data: res?.data?.chart?.Added,
          color: "#000000",
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 2],
          dashArray: [0, 5],
          colors: ["", "#000000"],
        },
        title: {
          text: "Total Registered",
        },
        markers: {
          size: 5,
          shape: "circle",
          colors: ["#44b8c5"],
          strokeColors: "#000000",
          strokeWidth: 2,
          hover: {
            sizeOffset: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: res?.data?.chart?.Day,
        xaxis: {
          type: "datetime",
        },
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: "Total Registered",
        type: "column",
        // data: [1],
        color: "#008FFB",
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 2],
        dashArray: [0, 5],
        colors: ["", "#000000"],
      },
      title: {
        text: "Total Registered",
      },
      markers: {
        size: 5,
        shape: "circle",
        colors: ["#44b8c5"],
        strokeColors: "#000000",
        strokeWidth: 2,
        hover: {
          sizeOffset: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      // labels: ["24 Jun 2024"],
      xaxis: {
        type: "datetime",
      },
    },
  });

  return (
    <div className="main-advancedashboard">
      <div className="psitionsss">
        <Header icon={faGauge} title={" Dashboard"} link={"/"} />
      </div>

      <section className="AdvanceDashboard">
        <div className="AdvanceDashboardTabs">
          <div className="row justify-content-end me-0 ms-0">
            <div className="col-xxl-12 col-12 p-0">
              <div className="tab-content" id="myTabContent">
                {/* overview tab start */}
                <div
                  className="tab-pane fade show active psitionsss22"
                  id="Overview-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Overview-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                    {isAllow?.includes(5) ? (
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis">
                          <div className="Total-Regis-holder">
                            <p className="sub-text">Total Registration</p>
                            <div className="user">
                              <p className="sub-text">Total User</p>
                              <div className="border-main"></div>
                              <p className="sub-text">Active User</p>
                            </div>
                            <div className="user">
                              <p className="sub-text">
                                {" "}
                                {data?.total_register_users}
                              </p>
                              <div className="border-main1"></div>
                              <p className="sub-text">
                                {data?.active_register_users}
                              </p>
                            </div>
                          </div>
                          <div className="sub-text-holder">
                            <p className="sub-text">
                              Today's User : {data?.total_today_register_users}
                            </p>
                          </div>
                        </div>
                      </div>
                      ) : (
                        <></>
                      )}
                         {isAllow?.includes(6) ? (
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis2">
                          <div className="Total-Regis-holder">
                            <p className="sub-text">Referral Activity</p>
                            <div className="user">
                              <p className="sub-text">Total User</p>
                              <div className="border-main"></div>
                              <p className="sub-text">Active User</p>
                            </div>
                            <div className="user">
                              <p className="sub-text">
                                {data?.total_referral_users}
                              </p>
                              <div className="border-main1"></div>
                              <p className="sub-text">
                                {data?.active_referral_users}
                              </p>
                            </div>
                          </div>
                          <div className="sub-text-holder">
                            <p className="sub-text">
                              Today's Referral :{" "}
                              {data?.total_today_referral_users}
                            </p>
                          </div>
                        </div>
                      </div>
                      ) : (
                        <></>
                      )}
                         {isAllow?.includes(7) ? (
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis3">
                          <div className="Total-Regis-holder">
                            <p className="sub-text">Total Funds</p>
                            <div className="user">
                              <p className="sub-text">
                                <span>Refferal Fund: </span> {data?.total_refferal_earning}
                              </p>
                            </div>
                            <div className="user">
                              <p className="sub-text">
                                <span>Deposit Fund : </span> {data?.total_fund_users}
                              </p>
                            </div>
                          </div>
                          <div className="sub-text-holder">
                            <p className="sub-text">
                              Today's Funds :{" "}
                              ₹ {data?.today_fund_users
                                ? data?.today_fund_users
                                : 0} | ₹ {data?.today_refferal_earning
                                  ? data?.today_refferal_earning
                                  : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                      ) : (
                        <></>
                      )}
                         {isAllow?.includes(8) ? (
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis4">
                          <div className="Total-Regis-holder">
                            <p className="sub-text">Withdrawal</p>
                            <div className="user">
                              <p className="sub-text"><span>Profit Withdrawal: </span>₹ {data?.total_profit_withdeawal_users || 0}</p>
                              {/* <div className="main">:</div>
                              <p className="sub-text">1234</p> */}
                            </div>
                            <div className="user">
                              {/* <p className="sub-text">Investment</p>
                              <div className="main">:</div> */}
                              <p className="sub-text"><span>Deposit Withdrawal: </span> ₹ {data?.total_fund_withdeawal_users}
                              </p>
                            </div>
                          </div>
                          <div className="sub-text-holder">
                            <p className="sub-text">
                              Today's Request :{" "}
                              ₹ {data?.today_profit_withdeawal_users
                                ? data?.today_profit_withdeawal_users
                                : 0} | ₹ {data?.today_fund_withdeawal_users
                                  ? data?.today_fund_withdeawal_users
                                  : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {/* overview tab end */}
                {/* ***************************************Graph*********************************** */}
                {isAllow?.includes(9) ? (
                <div className="Graph">
                  <div id="chart">
                    <ReactApexChart
                      options={chartOptions.options}
                      series={chartOptions.series}
                      type="line"
                      height={550}
                    />
                  </div>
                  <div id="html-dist"></div>
                </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Table;
