import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { formatDate } from "../../../../utils/common";
import Pagination from "../../../common/Pagination";
import { getAllData } from "../../../../utils/apis/PnL/PnL";
import ExportMethod from "../../../../utils/apis/ExportMethod";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import * as XLSX from 'xlsx';

// import * as XLSX from 'xlsx-style';

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (data) => { };

  const { getDownloadDataExcel, postData ,isAllow} = useContext(Context);

  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchName, setSearchName] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);

  const getData = async (search = "",sDate = "",eDate = "") => {
    const res = await getAllData(currentPage, perPage, search,sDate,eDate);
    setData(res?.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
    },
    {
      name: "Feedback",
      selector: "feedback",
      sortable: true,
    },
    {
      name: "Rating",
      selector: "rating",
      sortable: true,
    },
  ];

  //   const tableData = {
  //     columns,
  //     data: data.data,
  //   };

  const handleExportCSV = () => {
    const clientInfo = data?.data?.[0]?.pnl_client;
    const totalNetPnl = data?.data?.reduce((sum, item) => sum + parseFloat(item?.net_pnl), 0);
    // Define client info data
    const clientInfoData = [
      ['Client Basic Information'],
      ['Client Name', clientInfo.client_name],
      ['Client Id', clientInfo.client_id],
      ['PAN', clientInfo.pan],
      ['Date Of Download', formatDate(new Date())],
      [],
      ['Date Range'],
      ['From Date', formatDate(data?.data?.[0]?.transaction_date)],
      ['To Date', formatDate(data?.data?.[data?.data?.length - 1]?.transaction_date)],
      ['Net PnL', totalNetPnl],
      [],
      ['Transaction Date', 'Transaction Segment', 'Quantity', 'Buy Price', 'Sell Price', 'Net PnL']
    ];
  
    // Convert client info data to CSV format
    const formatCSVData = (rows) => rows.map(row => row.join(',')).join('\n');
  
    // Format client info data as CSV
    const clientInfoCSV = formatCSVData(clientInfoData);
  
    // Add transaction data
    const transactionData = data?.data?.map((item) => [
      item.transaction_date,
      item.transaction_segment,
      item.quantity,
      item.buy_price,
      item.sell_price,
      item.net_pnl,
    ]) || [];
  
    // Combine client info and transaction data
    const allData = clientInfoData.concat(transactionData);
    const csvContent = formatCSVData(allData);
  
    // Create CSV blob
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Pnl_Transaction.csv';
    link.click();
  };
  

  const handleExportExcel = () => {
    const clientInfo = data?.data?.[0]?.pnl_client;
  const totalNetPnl = data?.data?.reduce((sum, item) => sum + parseFloat(item?.net_pnl), 0);

    // Create worksheet data
    const clientInfoData = [
      ['Client Basic Information'],
      ['Client Name', clientInfo?.client_name],
      ['Client Id', clientInfo?.client_id],
      ['PAN', clientInfo?.pan],
      ['Date Of Download', formatDate(new Date())],
      [],
      ['Date Range'],
      ['From Date', formatDate(data?.data?.[0]?.transaction_date)],
      ['To Date', formatDate(data?.data?.[data?.data?.length - 1]?.transaction_date)],
      ['Net PnL', totalNetPnl],
      [],
      ['Transaction Date', 'Transaction segment', 'Quantity', 'Buy price', 'Sell Price', 'Net PnL']
    ];

    // Add transaction data
    const transactionData = data.data.map(item => ([
      item.transaction_date,
      item.transaction_segment,
      item.quantity,
      item.buy_price,
      item.sell_price,
      item.net_pnl
    ]));

    const worksheetData = clientInfoData.concat(transactionData);

    // Create worksheet and append data
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Set column widths
    ws['!cols'] = [
      { wch: 20 }, // Transaction Date
      { wch: 25 }, // Transaction segment
      { wch: 15 }, // Quantity
      { wch: 15 }, // Buy price
      { wch: 15 }, // Sell Price
      { wch: 15 }  // Net PnL
    ];


    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Append sheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Client Transactions');

    // Write workbook to file
    XLSX.writeFile(wb, 'Client_Transactions.xlsx');

    console.log('Excel file has been generated successfully.');
  };

const handlePrint = () => {
  const clientInfo = data?.data?.[0]?.pnl_client;
  const totalNetPnl = data?.data?.reduce((sum, item) => sum + parseFloat(item?.net_pnl), 0);
  // Client Information
  const clientInfoData = [
    ['Client Basic Information'],
    ['Client Name', clientInfo?.client_name],
    ['Client Id', clientInfo?.client_id],
    ['PAN', clientInfo?.pan],
    ['Date Of Download', formatDate(new Date())],
    [],
    ['Date Range'],
    ['From Date', formatDate(data?.data?.[0]?.transaction_date)],
    ['To Date', formatDate(data?.data?.[data?.data?.length - 1]?.transaction_date)],
    ['Net PnL', totalNetPnl],
    [],
    ['Transaction Date', 'Transaction Segment', 'Quantity', 'Buy Price', 'Sell Price', 'Net PnL']
  ];

  // Transaction Data
  const transactionData = data?.data?.map((item) => [
    item.transaction_date,
    item.transaction_segment,
    item.quantity,
    item.buy_price,
    item.sell_price,
    item.net_pnl,
  ]) || [];

  // Combine Client Info and Transaction Data
  const combinedData = clientInfoData.concat(transactionData);

  // Convert to HTML content for printing
  const content = `
    <html>
      <head>
        <title>Print Data</title>
        <style>
          table { width: 100%; border-collapse: collapse; }
          th, td { border: 1px solid black; padding: 8px; text-align: left  }
          th { background-color: #f2f2f2; }
          .maintd { background-color:#000  }
        </style>
      </head>
      <body>
        <table>
          ${combinedData.map((row, rowIndex) => `
            <tr>
              ${row.map((cell, cellIndex) => {
                let cellStyle = '';
                if (cell === 'Client Basic Information' || cell === 'Date Range' ) {
                  cellStyle = 'colspan="2" class="maintd" style="background-color:#000 !important; color:Black; font-weight:bold;"';
                }
                if (cell === 'Transaction Date' || cell ===  'Transaction Segment'  || cell ===  'Quantity'  || cell ===  'Buy Price'  || cell ===  'Sell Price'  || cell ===  'Net PnL' ) {
                  cellStyle = ' style="background-color:#000 !important; color:Black; font-weight:bold;"';
                }
                return `<td ${cellStyle}>${cell !== undefined ? cell : ''}</td>`;
              }).join('')}
            </tr>
          `).join('')}
        </table>
      </body>
    </html>
  `;

  // Open new window and print
  const printWindow = window.open("", "_blank");
  printWindow.document.write(content);
  printWindow.document.close();
  printWindow.print();
};


  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [showoff, setShowoff] = useState(false);

  const BulkUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const response = await postData("/pnl/pnl/bulk", formData, {});
      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
      } else {
        setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        // setShowoff(response.data);
      }, 1000);
      getData();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const Sample = async (e) => {
    try {
      await getDownloadDataExcel("/pnl/pnl/sample", {}, "Pnl");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };



  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"PNL"} link={"/pnl"} />
        <div className="search-investor mt-4">
          <div className="investor-main">
            <FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-3" />
            <p className="sub">Clients Transactions</p>
          </div>
          <div className="inward-payment">

          {isAllow?.includes(52) ? (
            <div className="nav-link active">
              <div className="dt-buttons btn-group flex-wrap">
                <button
                  className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportExcel}
                >
                  <span>Excel</span>
                </button>
                <button
                  className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handleExportCSV}
                >
                  <span>CSV</span>
                </button>
                <button
                  className="btn btn-secondary buttons-print Print_btn"
                  tabindex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                  onClick={handlePrint}
                >
                  <span>Print</span>
                </button>
              </div>
            </div>
              ) : (
                <></>
              )}

            {/* <div className="add me-3">
              <button
                className="btn btn-success pe-3"
                onClick={Sample}
              >
                Sample
              </button>
            

            </div> */}

            {/* <div className="add me-3">
              <button
                className="btn btn-success pe-3"
                onClick={() => document.getElementById("PinCodesFile").click()}
              >
                Bulk Upload
              </button>

              <input
                type="file"
                id="PinCodesFile"
                onChange={(e) => {
                  BulkUpload(e);
                }}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: "none" }}
              />
            </div> */}



            <form
              onSubmit={handleSubmit(onSubmit)}
              className="simple-form outward-main"
            >
              <div className="form-group">
                <label htmlFor="name">Search: </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                  placeholder="Search By Client Id"
                  id="name"
                  className="form-control"
                />
                <label htmlFor="name" className="ms-5">From Date: </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="Start Date"
                  className="form-control "
                />
                <label htmlFor="name"className="ms-5">To Date: </label>
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="End Date"
                  className="form-control "
                />

                <button className="btn btn-success ms-2" onClick={() => getData(searchName,startDate,endDate)}>Search</button>
              </div>
            </form>
          </div>
          {/* table started */}

          {/* <div className="main"> */}
          {/* <DataTableExtensions {...tableData}>
            <DataTable
              noHeader
              defaultSortAsc={true}
              // pagination
              highlightOnHover
            />
          </DataTableExtensions> */}
          {/* </div> */}
          <div className="table-main">
            <div className="table-responsive mt-2">
              <table className="table table-bordered  table-striped">
                <thead>
                  <tr>
                    <th className="name">
                      Client Name
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Client Id
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Date
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Transaction Segment
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Quantity
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Buy Price
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                    <th className="name">
                      Sell price
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>

                    <th className="name">
                      Net Pnl
                      <div className="triangle"></div>
                      <div className="triangle2"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((data, index) => (
                    <tr className="odd" key={index}>
                      <td>{data?.pnl_client?.client_name}</td>
                      <td>{data?.pnl_client?.client_id}</td>
                      <td>{data?.transaction_date}</td>
                      <td>{data?.transaction_segment}</td>
                      <td>{data?.quantity}</td>
                      <td>{data?.buy_price}</td>
                      <td>{data?.sell_price}</td>
                      <td>{data?.net_pnl}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* table ended */}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            TotalPages={TotalPages}
            TotalEntries={TotalEntries}
            perPage={perPage}
          />
        </div>
      </div>
    </>
  );
};

export default Tables;

// import React from "react";
// import ReactDOM from "react-dom";
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";

// const Tables = () => {
//   const columns = [
//     {
//       name: "Name",
//       selector: "name",
//       sortable: true,
//     },
//     {
//       name: "Position",
//       selector: "education.position",
//       sortable: true,
//     },
//     {
//       name: "Office",
//       selector: "office",
//       sortable: true,
//     },
//     {
//       name: "Age",
//       selector: "age",
//       sortable: true,
//     },
//     {
//       name: "Start date",
//       selector: "start_date",
//       sortable: true,
//     },
//     {
//       name: "Salary",
//       selector: "salary",
//       sortable: true,
//     },
//   ];

//   const data = [
//     {
//       name: "Tiger Nixon",
//       education: {
//         position: "System Architect",
//       },
//       office: "Edinburgh",
//       age: "61",
//       start_date: "2011/04/25",
//       salary: "$320,800",
//     },
//     {
//       name: "Garett Winters",
//       education: {
//         position: "Accountant",
//       },
//       office: "Tokyo",
//       age: "63",
//       start_date: "2011/07/25",
//       salary: "$170,750",
//     },
//   ];

//   const tableData = {
//     columns,
//     data,
//   };
//   return (
//     <div className="main">
//       <DataTableExtensions {...tableData}>
//         <DataTable
//           noHeader
//           defaultSortAsc={false}
//           // pagination
//           highlightOnHover
//         />
//       </DataTableExtensions>
//     </div>
//   );
// };

// export default Tables;
