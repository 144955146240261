import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";

import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPerson, faUser, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Col from "react-bootstrap/Col";

import Row from "react-bootstrap/Row";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Datepicker from "../../../common/Datepicker";
import { Link } from "react-router-dom";
import {
  City,
  getSetup,
  Occupation,
  State,
} from "../../../../utils/apis/masters/masters";
import { Select2Data, formatDate, getPaginationMessage } from "../../../../utils/common";
import { getAllData } from "../../../../utils/apis/Investores/Investores";
import Pagination from "../../../common/Pagination";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import { editStatusData } from "../../../../utils/api";
import KycDetails from "../../../common/KycDetails";
import Success_modal from "../../../common/Success_modal/Success_modal";
import {
  InwardAccepted,
  InwardManual
} from "../../../../utils/apis/Investores/Investores";
import { Modal, Button } from "react-bootstrap";
import * as XLSX from 'xlsx';

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  // hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      from: "",
      to: "",
      state_id: null,
      city_id: null,
      occupation_id: null,
      service_number: "",
    },
  });
  const [modalShow, setModalShow] = useState(false);
  const [modalShowAmount, setModalShowAmount] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const onSubmit = async (data) => {
    console.log(data);
  };
  const { IMG_URL, isAllow } = useContext(Context);
  const [state, setState] = useState([]);
  const [detailType, setType] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [city, setCity] = useState([]);
  const [setup, setSetup] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [kycID, setKycID] = useState("");
  const [kycModal, setKycModal] = useState(false);
  const verifyStatus = [
    { value: 'Unverified', label: 'Unverified' },
    { value: 'Accepted', label: 'Accepted' },
    { value: 'Rejected', label: 'Rejected' },
  ];


  const getData = async () => {

    const res = await getAllData(
      getValues("state_id"),
      getValues("city_id"),
      getValues("occupation_id"),
      getValues("service_number"),
      getValues("investor_name"),
      getValues("from"),
      getValues("to"),
      getValues("bank_status"),
      getValues("kyc_status"),
      currentPage,
      perPage
    );
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  const getMastersData = async () => {
    {
      const res = await State();
      await setState(await Select2Data(res?.data, "state_id"));
    }
    {
      const res = await Occupation();
      await setOccupation(await Select2Data(res?.data, "occupation_id"));
    }
    {
      const res = await getSetup();
      await setSetup(res?.data);
    }
  };

  const handleStateSelect = async (id) => {
    const res = await City(id);
    if (res?.success) {
      await setCity(await Select2Data(res?.data, "city_id"));
    }
  };

  const [changeStatus, setChangeStatus] = useState();
  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/investors/investors/${id}`);
    setChangeStatus(response);
    getData();
  };

  useEffect(() => {
    getMastersData();
  }, []);

  //

  const handleExportCSV = () => {
    const header = [
      { name: "Investor Name" },
      { name: "Service Number" },
      { name: "Email" },
      { name: "State" },
      { name: "District" },
      { name: "Gender" },
      { name: "Age" },
      { name: "Deposited Amount" },
      { name: "App Version" },
      { name: "KYC Verify Status" },
      { name: "Bank Verify Status" },
      { name: "Profit And Loss " },
      { name: "Referred Count " },
      { name: "Referral Amount " },
      { name: "Date " },
    ];
    const exportData = data?.data?.map((data) => [
      data?.f_name + " " + data?.l_name,
      data?.contact_no,
      data?.email,
      data?.users_detail?.state?.name || "-",
      data?.users_detail?.city?.name || "-",
      data?.users_detail?.gender || "-",
      data?.users_detail?.age || "-",
      data?.amount || 0,
      compareVersions(data?.app_version, setup?.app_version) ? '<p className="text-success">Updated</p>' : '<p className="text-danger">Not Updated</p>',
      data?.kyc_detail?.verify_status || "-",
      data?.kyc_detail?.bank_status || "-",
      data?.profit_loss,
      data?.user?.length || 0,
      data?.total_referral_earn,
      formatDate(data?.createdAt),
    ]);
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "Investor"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [
      { name: "Investor Name" },
      { name: "Service Number" },
      { name: "Email" },
      { name: "State" },
      { name: "District" },
      { name: "Gender" },
      { name: "Age" },
      { name: "Deposited Amount" },
      { name: "App Version" },
      { name: "KYC Verify Status" },
      { name: "Bank Verify Status" },
      { name: "Profit And Loss " },
      { name: "Referred Count " },
      { name: "Referral Amount " },
      { name: "Date " },

    ];
    const exportData = data?.data?.map((data) => [
      data?.f_name + " " + data?.l_name,
      data?.contact_no,
      data?.email,
      data?.users_detail?.state?.name || "-",
      data?.users_detail?.city?.name || "-",
      data?.users_detail?.gender || "-",
      data?.users_detail?.age || "-",
      data?.amount || 0,
      compareVersions(data?.app_version, setup?.app_version) ? '<p className="text-success">Updated</p>' : '<p className="text-danger">Not Updated</p>',
      data?.kyc_detail?.verify_status || "-",
      data?.kyc_detail?.bank_status || "-",

      data?.profit_loss,
      data?.user?.length || 0,
      data?.total_referral_earn,
      formatDate(data?.createdAt),
    ]);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "Investor"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = () => {
    const header = [
      { name: "Investor Name" },
      { name: "Service Number" },
      { name: "Email" },
      { name: "State" },
      { name: "District" },
      { name: "Gender" },
      { name: "Age" },
      { name: "Deposited Amount" },
      { name: "App Version" },
      { name: "KYC Verify Status" },
      { name: "Bank Verify Status" },
      { name: "Profit And Loss " },
      { name: "Referred Count " },
      { name: "Referral Amount " },
      { name: "Date " },
    ];
    const exportData = data?.data?.map((data) => [
      data?.f_name + " " + data?.l_name,
      data?.contact_no,
      data?.email,
      data?.users_detail?.state?.name || "-",
      data?.users_detail?.city?.name || "-",
      data?.users_detail?.gender || "-",
      data?.users_detail?.age || "-",
      data?.amount || 0,
      compareVersions(data?.app_version, setup?.app_version) ? '<p className="text-success">Updated</p>' : '<p className="text-danger">Not Updated</p>',
      data?.kyc_detail?.verify_status || "-",
      data?.kyc_detail?.bank_status || "-",
      data?.profit_loss,
      data?.user?.length || 0,
      data?.total_referral_earn,
      formatDate(data?.createdAt),
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };

  // const Accepted = async (id) => {
  //   const amount = getValues(`amount_${id}`);

  //   const data = {
  //     amount: amount,
  //     id: id,
  //   };
  //   if (amount > 0) {
  //     const res = await InwardManual(data);
  //     if (res.success) {
  //       reset({ [`amount_${id}`]: "" });
  //       getData();
  //       setModalShow(true)
  //     }
  //     // getData();
  //     // Proceed with your logic here
  //   } else {
  //     await setError(`amount_${id}`, {
  //       type: "manual",
  //       message: "Enter amount",
  //     });
  //   }
  // };


  const compareVersions = (version1, version2) => {
    if (version1 && version2) {
      const v1Parts = version1.split('.').map(Number);
      const v2Parts = version2.split('.').map(Number);

      const concatenated1 = v1Parts.join('');
      const concatenated2 = v2Parts.join('');

      console.log(concatenated1 < concatenated2, 'concatenated1');


      if (concatenated1 < concatenated2) {
        return 0;
      } else {
        return 1;
      }

      return 0;

      // for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      //   const v1 = v1Parts[i] || 0; // If the part is undefined, treat it as 0
      //   const v2 = v2Parts[i] || 0;


      //   if (v1 < v2) {
      //     return 0;
      //   }
      //   if (v1 >= v2) {
      //     return 1;
      //   }
      // }

      // return 0;
    }

  };

  const [showModal, setShowModal] = useState(false);
  const [showModalData, setShowModalData] = useState([]);

  const handleOpenModal = (data) => {
    setShowModalData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  console.log(showModalData, "showModalData showModalData");


  const handleExportExcelRefered = () => {
    const clientInfo = showModalData?.[0]?.user;
    const totalNetPnl = data?.data?.reduce((sum, item) => sum + parseFloat(item?.net_pnl), 0);

    // Create worksheet data
    const clientInfoData = [
      ['Invester Name'],
      ['Name', clientInfo?.f_name],
      ['Contact No', clientInfo?.contact_no],

      [],

      ['First Name', 'Last Name', 'Email', 'Contact', 'Amount']
    ];

    // Add transaction data
    const transactionData = showModalData.map(item => ([
      item.refer.f_name,
      item.refer.l_name,
      item.refer.email,
      item.refer.contact_no,
      item.refer.total,

    ]));

    const worksheetData = clientInfoData.concat(transactionData);

    // Create worksheet and append data
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Set column widths
    ws['!cols'] = [
      { wch: 10 }, // Transaction Date
      { wch: 10 }, // Transaction segment
      { wch: 30 }, // Quantity
      { wch: 15 }, // Buy price
      { wch: 15 }, // Sell Price
      { wch: 15 }  // Net PnL
    ];


    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Append sheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Referel');

    // Write workbook to file
    XLSX.writeFile(wb, 'Referel Count.xlsx');

    console.log('Excel file has been generated successfully.');
  };

  const Accepted = (id) => {
    setSelectedId(id); // Store the selected ID to access inside the modal
    setModalShowAmount(true); // Open the modal
  };

  const handleSubmitData = async (id) => {
    const amount = getValues(`amount_${id}`);
    const utr_id = getValues(`utr_no_${id}`);

    const data = { id, amount, utr_id };

    if (amount > 0 && utr_id) {
      const res = await InwardManual(data);
      if (res.success) {
        reset({ [`amount_${id}`]: "", [`utr_no_${id}`]: "" });
        getData();
        setModalShowAmount(false); // Close the modal after successful submission
      } else {
        setError(`utr_no_${id}`, { type: "manual", message: res?.message });
      }
    } else {
      if (amount <= 0) {
        setError(`amount_${id}`, { type: "manual", message: "Enter a valid amount" });
      }
      if (!utr_id) {
        setError(`utr_no_${id}`, { type: "manual", message: "Enter UTR No" });
      }
    }
  };
  return (
    <>
      <div className="main-advancedashboard">
        <div className="investor">
          {/* <FontAwesomeIcon icon={faUser} /> */}
          <Header icon={faPerson} title={"Investor"} link={"/investor"} />
          <div className="search-investor">
            <h4 className="title">Search Investor</h4>
            <div className="date mt-3">
              <Datepicker
                reset={reset}
                getValues={getValues}
                setValue={setValue}
              />
              <form onSubmit={handleSubmit(onSubmit)} className="d-flex">
                <p className="sub">Investor Name</p>
                <div>
                  <input
                    type="text"
                    id="investor_name"
                    {...register("investor_name")}
                    className="form-control"
                    placeholder="Investor name"
                  />
                </div>
                <p className="sub">Service Number</p>
                <div>
                  <input
                    type="text"
                    id="firstName"
                    {...register("service_number")}
                    className="form-control"
                    placeholder="Service Number"
                  />
                </div>
              </form>
            </div>
            <Row className="mt-4">
              <Col xxl={3} xl={3} lg={4}>
                <p className="sub">State</p>
                <Controller
                  name="state_id"
                  {...register("state_id", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.state_id ? "red" : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="State"
                      {...field}
                      options={state}
                      onChange={(selectedOption) => {
                        setValue("state_id", null);
                        field.onChange(selectedOption);
                        handleStateSelect(selectedOption.value);
                      }}
                    />
                  )}
                />
                {/* <Select
                  id="inputState"
                  options={state}
                  placeholder="State"
                  classNamePrefix="custom-select-class"
                /> */}
              </Col>
              <Col xxl={3} xl={3} lg={4}>
                <p className="sub">District</p>
                <Controller
                  name="city_id"
                  {...register("city_id", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.city_id ? "red" : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="City"
                      {...field}
                      options={city}
                      onChange={(selectedOption) => {
                        setValue("city_id", null);
                        field.onChange(selectedOption);
                        // handleStateSelect(selectedOption.value);
                      }}
                    />
                  )}
                />
                {/* <Select
                  id="inputState"
                  options={locationOptions}
                  placeholder="State"
                  classNamePrefix="custom-select-class"
                /> */}
              </Col>
              <Col xxl={3} xl={3} lg={4}>
                <p className="sub">Occupation</p>
                <Controller
                  name="occupation_id"
                  {...register("occupation_id", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.occupation_id
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="Occupation"
                      {...field}
                      options={occupation}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        // handlePincodeSelect(selectedOption.value);
                      }}
                    />
                  )}
                />
              </Col>
              <Col xxl={3} xl={3} lg={4}>
                <p className="sub">KYC Status</p>
                <Controller
                  name="kyc_status"
                  {...register("kyc_status", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.kyc_status
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="KYC Status"
                      {...field}
                      options={verifyStatus}
                    />
                  )}
                />
              </Col>
              <Col xxl={3} xl={3} lg={4}>
                <p className="sub">Bank Status</p>
                <Controller
                  name="bank_status"
                  {...register("bank_status", {})}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.bank_status
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      classNamePrefix="custom-select-class"
                      placeholder="Bank Status"
                      {...field}
                      options={verifyStatus}

                    />
                  )}
                />
              </Col>
              <Col xxl={2} xl={2} lg={2}>
                <p className="sub"></p>
                <button
                  type="button"
                  onClick={() => {
                    getData();
                  }}
                  className="search"
                >
                  <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                  <p>Search</p>
                </button>
              </Col>
              <Col xxl={1} xl={1} lg={2}>
                <p className="sub"></p>
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    setValue('kyc_status', "");
                    setValue('bank_status', "");
                    getData();
                  }}
                  className="search"
                >
                  {/* <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /> */}
                  <p>Reset</p>
                </button>
              </Col>
            </Row>
          </div>
          <div className="search-investor ">
            <div className="investor-main">
              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
              <p className="sub">Investor</p>
            </div>
            {isAllow?.includes(28) ? (
              <div className="nav-link active">
                <div className="dt-buttons btn-group flex-wrap">
                  <button
                    className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportExcel}
                  >
                    <span>Excel</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportCSV}
                  >
                    <span>CSV</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-print Print_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handlePrint}
                  >
                    <span>Print</span>
                  </button>

                  <input type="number" className="ps-3" onChange={(e) => setperPage(e.target.value)} placeholder="Enter Per Page Data"></input>

                </div>
              </div>
            ) : (
              <></>
            )}
            {/* table started */}
            <div className="table-main">
              <div className="table-responsive mt-2">
                <table className="table table-bordered  table-striped">
                  <thead>
                    <tr>
                      <th className="name">
                        Investor Name
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Service Number
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        State
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        District
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Gender
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Age
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Date
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Deposited Amount
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        App Version
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Referred Count
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Referral Amount
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      <th className="name">
                        Action
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th>
                      {isAllow?.includes(26) ? (
                        <th className="name">
                          KYC Details
                          <div className="triangle"></div>
                          <div className="triangle2"></div>
                        </th>) : (
                        <></>
                      )}
                      {isAllow?.includes(25) ? (
                        <th className="name">
                          Bank Account Details
                          <div className="triangle"></div>
                          <div className="triangle2"></div>
                        </th>
                      ) : (
                        <></>
                      )}
                      {/* <th className="name">
                        Amount
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th> */}
                        {isAllow?.includes(27) ? (

                      <th className="name">
                        Action
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th> ) : (
                          <></>
                        )}
                      {/* <th className="name">
                        Payment Screenshot
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((data, index) => (
                      <tr className="odd" key={index}>
                        <td>
                          {data?.f_name} {data?.l_name}
                        </td>
                        <td>{data?.contact_no}</td>
                        <td>{data?.users_detail?.state?.name}</td>
                        <td>{data?.users_detail?.city?.name}</td>
                        <td>{data?.users_detail?.gender}</td>
                        <td>{data?.users_detail?.age}</td>
                        <td>{formatDate(data?.createdAt)}</td>
                        <td>{data?.amount}</td>
                        <td>{data?.app_version}  {compareVersions(data?.app_version, setup?.app_version) ? (
                          <span className="text-success">(Updated)</span>
                        ) : (
                          <span className="text-danger">(Not Updated)</span>
                        )}</td>
                        <td onClick={() => handleOpenModal(data?.user)} style={{ cursor: "pointer" }}>
                          {data?.user?.length}
                        </td>
                        <td onClick={() => handleOpenModal(data?.user)} style={{ cursor: "pointer" }}>
                          {data?.total_referral_earn}
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={data.status}
                              onChange={() => {
                                ChangeStatus(data.id);
                              }}
                              id={`flexSwitchCheckDefault${data?.id}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`flexSwitchCheckDefault${data?.id}`}
                            >
                              {data.status ? "Active" : "Inactive"}
                            </label>
                          </div>
                        </td>
                        {isAllow?.includes(26) ? (
                          <td>
                            <div className="form-check form-switch">
                              <button className="btn btn-info" onClick={() => { setKycID(data); setKycModal(true); setType('KYC'); }}>View</button>
                            </div>
                            <sub><b>Verify Status: </b> <span className={
                              data?.kyc_detail?.verify_status === "Unverified" ? "text-warning" :
                                data?.kyc_detail?.verify_status === "Accepted" ? "text-success" :
                                  data?.kyc_detail?.verify_status === "Rejected" ? "text-danger" : "text-warning"
                            }>
                              {data?.kyc_detail?.verify_status}
                            </span></sub>
                          </td>) : (
                          <></>
                        )}
                        {isAllow?.includes(25) ? (
                          <td>
                            <div className="form-check form-switch">
                              <button className="btn btn-info" onClick={() => { setKycID(data); setKycModal(true); setType('Bank'); }}>View</button>
                            </div>
                            <sub><b>Verify Status: </b> <span className={
                              data?.kyc_detail?.bank_status === "Unverified" ? "text-warning" :
                                data?.kyc_detail?.bank_status === "Accepted" ? "text-success" :
                                  data?.kyc_detail?.bank_status === "Rejected" ? "text-danger" : "text-warning"
                            }>
                              {data?.kyc_detail?.bank_status}
                            </span></sub>
                          </td>
                        ) : (
                          <></>
                        )}
                        {/* <td>
                          <div className="form-group me-2">
                            <input
                              type="number"
                              placeholder="Amount"
                              {...register(`amount_${data.id}`, {})}
                              className={`form-control ${errors[`amount_${data.id}`] ? "is-invalid" : ""
                                }`}
                            />
                          </div>
                        </td> */}
                        {isAllow?.includes(27) ? (
                          <td>
                            <button
                              onClick={() => {
                                Accepted(data.id);
                              }}
                              type="button"
                              className="btn btn-primary"
                            >
                              Add Amount
                            </button>
                          </td>
                        ) : (
                          <></>
                        )}
                        {/* <td
                        valign="top"
                        colspan="10"
                        className="dataTables_empty text-center"
                      >
                        No data available in table
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* table ended */}

            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              TotalPages={TotalPages}
              TotalEntries={TotalEntries}
              perPage={perPage}
            />

            {/* <div className="row">
              <div className="col-md-6 ">
                <div className="entries-section justify-content-left">
                  <div className="entries-section">
                    <p>
                      {getPaginationMessage(TotalEntries, perPage, currentPage)}{" "}
                    </p>
                  </div>
               
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="justify-content-left">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-md-end justify-content-start">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          onClick={handlePrevious}
                          to="#"
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={`page-item ${
                          currentPage === TotalPages ? "disabled" : ""
                        }`}
                      >
                        <Link className="page-link" onClick={handleNext} to="#">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div >

      <KycDetails show={kycModal} onHide={() => setKycModal(false)} kycID={kycID} getData={getData} detailType={detailType} />
      <Success_modal
        maintext={"Message Sent Successfully"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Modal show={showModal} onHide={handleCloseModal}>

        <Modal.Header closeButton>
          <button
            className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
            tabindex="0"
            aria-controls="DataTables_Table_0"
            type="button"
            onClick={handleExportExcelRefered}
            style={{ margin: '10px' }}
          >
            <span>Excel</span>
          </button>
          <Modal.Title>User List</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          {/* Display user details in two columns */}
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {showModalData?.map((user, index) => (
                <tr key={index}>
                  <td>{user.refer.f_name} {user.refer.l_name}</td>
                  <td>{user.refer.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalShowAmount} onHide={() => setModalShowAmount(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="number"
              placeholder="Enter Amount"
              {...register(`amount_${selectedId}`, {})}
              className={`form-control ${errors[`amount_${selectedId}`] ? "is-invalid" : ""}`}
            />
            {errors[`amount_${selectedId}`] && (
              <div className="invalid-feedback">
                {errors[`amount_${selectedId}`].message}
              </div>
            )}
          </div>
          <div className="form-group">
            <label>UTR No</label>
            <input
              type="text"
              placeholder="Enter UTR No"
              {...register(`utr_no_${selectedId}`, {})}
              className={`form-control ${errors[`utr_no_${selectedId}`] ? "is-invalid" : ""}`}
            />
            {errors[`utr_no_${selectedId}`] && (
              <div className="invalid-feedback">
                {errors[`utr_no_${selectedId}`].message}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShowAmount(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSubmitData(selectedId)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default Tables;
