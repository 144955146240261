import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";



export const getAllTransferData = async (page, per_page, search) => {
  try {
    let url = `/payment/transfer-payment`;

    if (page) {
      url += `?page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

