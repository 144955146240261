import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";

import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRotate, fas } from "@fortawesome/free-solid-svg-icons";

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { event } from "jquery";

const localizer = momentLocalizer(moment);

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    IMG_URL,postData,
  } = useContext(Context);

  


  const [events, setEvents] = useState([
  ]);

  const GetEditData = async () => {
    const response = await getData(`/calender`);
    setEvents(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const handleSelectSlot = async({ start, end }) => {
    const title = window.prompt('Enter event title');
    if (title) {
      const newEvent = {
        start,
        end,
        title,
        color: getRandomColor() // Assign a random color
      };
      
      setEvents((prevEvents) => [...prevEvents, newEvent]);
      console.log(newEvent,"event");
      const response = await postData("/calender", newEvent);
      if (response.success) {
    
      } 
      
    }
  };

  const handleSelectEvent = async (event) => {
    const action = window.prompt(
      `Do you want to 'edit' or 'delete' the event titled "${event.title}"? Type 'edit' or 'delete'.`
    );

    console.log(event,"event eventedit delte");
    

    if (action === 'edit') {
      const newTitle = window.prompt('Edit event title', event.title);
      if (newTitle) {
        const updatedEvent = { ...event, title: newTitle };

        setEvents((prevEvents) =>
          prevEvents.map((e) => (e === event ? updatedEvent : e))
        );

        const response = await postData(`/calender/${event?.id}`, updatedEvent);
        if (response.success) {
          console.log('Event successfully updated on the server');
        }
      }
    } else if (action === 'delete') {
      if (window.confirm(`Are you sure you want to delete the event '${event.title}'?`)) {
        setEvents((prevEvents) => prevEvents.filter((e) => e !== event));
        const response = await deleteData(`/calender/${event?.id}`);
        if (response.success) {
          console.log('Event successfully deleted from the server');
        }
      }
    }else {
      // Handle invalid input
      window.alert("Invalid action. Please type 'edit' or 'delete'.");
     
    }
  };

  const handleEventDelete = async(event) => {

    if (window.confirm(`Are you sure you want to delete the event '${event.title}'?`)) {

      setEvents((prevEvents) => prevEvents.filter((e) => e !== event));
      const response = await deleteData(
        `/calender/${event?.id}`
      );
    }
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color; // Use the color from the event data
    return {
      style: {
        backgroundColor,
        color: 'white',
        borderRadius: '5px',
        border: 'none',
        display: 'block',
      }
    };
  };

  console.log(events, 'events');


  return (
    <>
      <div className="main-advancedashboard addasbordssd">
        <Header title={"Calendar"} link={"/masters/app-setup"} />
        <section className="Advancemain-advancedashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-3">
              <div className="">
                <div className="row">
                  <div className="d-flex">
                    <div className="add me-3">

                    </div>
                  </div>

                </div>
                <div className="border-line mt-2"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                  <Calendar
                      selectable
                      localizer={localizer}
                      events={events}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      onSelectSlot={handleSelectSlot}
                      onSelectEvent={handleSelectEvent} // Handle both edit and delete
                      eventPropGetter={eventStyleGetter}
                    />
                    {/* <Calendar
                      selectable
                      localizer={localizer}
                      events={events}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 800 }}
                      onSelectSlot={handleSelectSlot}
                      onSelectEvent={handleEventDelete} // Handle event deletion
                      eventPropGetter={eventStyleGetter}
                    /> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default Tables;
