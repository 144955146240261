import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { getAllReportsData } from "../../../../utils/apis/Inward/Inward";
import Pagination from "../../../common/Pagination";
import { formatDate } from "../../../../utils/common";
import ScreenShot from "../../../common/ScreenShot";
import { Context } from "../../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import EditAmount from "../../../common/EditAmont";

const Reports = ({
  status,
  setStatus,
  handleExportCSV,
  handlePrint,
  handleExportExcel,isAllow
}) => {
  const { IMG_URL } = useContext(Context);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [inwardID, setID] = useState(false);
  const [previousAmount, setAmount] = useState("");

  const getData = async (search = "") => {
    const res = await getAllReportsData(currentPage, perPage, search);
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage, status]);
  return (
    <div className="search-investor mt-4">
      <p className="sub">Report</p>
      {isAllow?.includes(39) ? (
      <div className="inward-payment">
        <div className="nav-link active">
          <div className="dt-buttons btn-group flex-wrap">
            <button
              className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportExcel(data, "Report")}
            >
              <span>Excel</span>
            </button>
            <button
              className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handleExportCSV(data, "Report")}
            >
              <span>CSV</span>
            </button>
            <button
              className="btn btn-secondary buttons-print Print_btn"
              tabindex="0"
              aria-controls="DataTables_Table_0"
              type="button"
              onClick={() => handlePrint(data, "Report")}
            >
              <span>Print</span>
            </button>
            
            <input type="number" className="ps-3" onChange={(e) => setperPage(e.target.value)} placeholder="Enter Per Page Data"></input>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="name">Search: </label>
          <input
            type="text"
            id="name"
            placeholder="Serch By Service No"
            onChange={(e) => {
              getData(e.target.value);
            }}
            // {...register("name", { required: "Name is required" })}
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
          />
          {/* {errors.name && (
            <div className="invalid-feedback">{errors.name.message}</div>
          )} */}
        </div>
      </div>
      ) : (
        <></>
      )}
      {/* table started */}
      <div className="table-main">
        <div className="table-responsive mt-2">
          <table className="table table-bordered  table-striped">
            <thead>
              <tr>
                <th className="name">
                  Payment Date
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Investor Name
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  Service No
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  State
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  District
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                <th className="name">
                  UTR ID
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>

                <th className="name">
                  Amount
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th>
                {/* <th className="name">
                  Action
                  <div className="triangle"></div>
                  <div className="triangle2"></div>
                </th> */}
              </tr>
              {/* )} */}
            </thead>
            <tbody>
              {data?.data?.map((data, index) => (
                <tr className="odd" key={index}>
                  <td>{formatDate(data?.createdAt)}</td>
                  <td>
                    {data?.user?.f_name} {data?.user?.l_name}
                  </td>
                  <td>{data?.user?.contact_no}</td>
                  <td>{data?.user?.users_detail?.state?.name}</td>
                  <td>{data?.user?.users_detail?.city?.name}</td>
                  <td>{data?.utr_id}</td>
                  <td>
                    ₹ <b>{data?.amount}{" "}</b>
                    {/* <FontAwesomeIcon
                      icon={faPen}
                      className="custom-icon"
                      style={{
                        color: "blue",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setID(data?.id);
                        setAmount(data?.amount);
                        setEditModal(true);
                      }}
                    /> */}
                  </td>
                  {/* <td>{data?.accepted_rejected ? "Accepted" : "Rejected"}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* table ended */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        TotalPages={TotalPages}
        TotalEntries={TotalEntries}
        perPage={perPage}
      />

      <ScreenShot show={modalShow} onHide={() => setModalShow(false)} />
      <EditAmount
        show={editModal}
        onHide={() => setEditModal(false)}
        inwardID={inwardID}
        getData={getData}
        previousAmount={previousAmount}
      />
    </div>
  );
};

export default Reports;
