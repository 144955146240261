import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import {
  AddButton,
  EditButton,
  DeletButton,
  Submit,
} from "../../../common/Button";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ScreenShot from "../../../common/ScreenShot";
import { Investor } from "./Investor";
import Reports from "./Reports";
import ExportMethod from "../../../../utils/apis/ExportMethod";
import { formatDate } from "../../../../utils/common";
const axios = require("axios");
library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  const { IMG_URL, isAllow } = useContext(Context);

  const [status, setStatus] = useState();

  const handleExportCSV = (data, file) => {
    const header = [
      { name: "Payment Date" },
      { name: "Investor Name" },
      { name: "Service No" },
      { name: "State" },
      { name: "District" },
      { name: "UTR ID" },
      { name: "Amount" },
    ];
    const exportData = data?.data?.map((data) => [
      formatDate(data.createdAt),
      data?.user?.f_name + " " + data?.user?.l_name,
      data?.user?.contact_no,
      data?.user?.users_detail?.state?.name,
      data?.user?.users_detail?.city?.name,
      data?.utr_id,
      // IMG_URL + data?.screenshot,
      data?.amount,
    ]);
    const { content, type, name } = ExportMethod.csv(exportData, header, file);
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  // const handleExportExcel = (data, file) => {
  //   const header = [
  //     { name: "Payment Date" },
  //     { name: "Investor Name" },
  //     { name: "State" },
  //     { name: "District" },
  //     { name: "Age" },
  //     { name: "Email" },
  //     { name: "UTR ID" },
  //     { name: "Paymnet Screenshot" },
  //     { name: "Amount" },
  //     { name: "Status" },
  //   ];

  //   const exportData = data?.data?.map((data) => [
  //     formatDate(data.createdAt),
  //     data?.user?.f_name + " " + data?.user?.l_name,
  //     data?.user?.users_detail?.state?.name,
  //     data?.user?.users_detail?.city?.name,
  //     data?.user?.users_detail?.age,
  //     data?.user?.email,
  //     data?.utr_id,
  //     IMG_URL + data?.screenshot,
  //     data?.amount,
  //     data?.accepted_rejected ? "Accepted" : "Rejected",
  //   ]);

  //   const { content, type, name } = ExportMethod.excel(
  //     exportData,
  //     header,
  //     file
  //   );
  //   const blob = new Blob([content], { type });
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = name;
  //   link.click();
  // };

  const handleExportExcel = async (data, file) => {
    const header = [
      { name: "Payment Date" },
      { name: "Investor Name" },
      { name: "Service No" },
      { name: "State" },
      { name: "District" },
      { name: "UTR ID" },
      { name: "Amount" },
    ];

    const exportData = data?.data?.map(async (data) => {
      const formattedDate = formatDate(data.createdAt);
      const investorName = data?.user?.f_name + " " + data?.user?.l_name;
      const contact_no = data?.user?.contact_no;
      const stateName = data?.user?.users_detail?.state?.name;
      const cityName = data?.user?.users_detail?.city?.name;
      const utrId = data?.utr_id;
      const amount = data?.amount;

      // Convert image URL to base64
      // const screenshotBase64 = await convertImageToBase64(
      //   IMG_URL + data?.screenshot
      // );

      // console.log(screenshotBase64);

      return [
        formattedDate,
        investorName,
        contact_no,
        stateName,
        cityName,
        utrId,
        // screenshotBase64, // Use base64 string here
        amount,
      ];
    });

    // Wait for all promises to resolve
    const resolvedExportData = await Promise.all(exportData);

    const { content, type, name } = ExportMethod.excel(
      resolvedExportData,
      header,
      file
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  // Helper function to convert image URL to base64
  async function convertImageToBase64(imageUrl) {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });
      return Buffer.from(response, "binary").toString("base64");
    } catch (error) {
      console.error("Error converting image to base64:", error);
      return ""; // Return empty string or handle error as needed
    }
  }

  const handlePrint = (data, file) => {
    const header = [
      { name: "Payment Date" },
      { name: "Investor Name" },
      { name: "Service No" },
      { name: "State" },
      { name: "District" },
      { name: "UTR ID" },
      { name: "Amount" },
    ];
    const exportData = data?.data?.map((data) => [
      formatDate(data.createdAt),
      data?.user?.f_name + " " + data?.user?.l_name,
      data?.user?.contact_no,
      data?.user?.users_detail?.state?.name,
      data?.user?.users_detail?.city?.name,
      data?.utr_id,
      // IMG_URL + data?.screenshot,
      data?.amount,
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Inward Payment"} link={"/payment/inward-payment"} />
        <section className="AdvanceDashboard">
          {isAllow?.includes(35) ? (
            <Investor
              isAllow={isAllow}
              status={status}
              setStatus={setStatus}
              handleExportCSV={handleExportCSV}
              handlePrint={handlePrint}
              handleExportExcel={handleExportExcel}
            />) : (
            <></>
          )}
          {isAllow?.includes(36) ? (
            <Reports
              isAllow={isAllow}
              status={status}
              setStatus={setStatus}
              handleExportCSV={handleExportCSV}
              handlePrint={handlePrint}
              handleExportExcel={handleExportExcel}
            />) : (
            <></>
          )}
        </section>
      </div>

      {/* {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}
      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      /> */}
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
